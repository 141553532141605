<template>
  <div>
    <div class="row align-items-stratch">
      <div class="col-3 my-3 h-100">
        <div
          
          class="h-100 btn border border-3 rounded border-secondary w-100 d-flex justify-content-center align-items-center"
          :style="{
            borderStyle: 'dashed !important',
          }"
        >
          <img
            v-show="item.image"
            :src="item.image"
            alt=""
            id="imagePreview"
            class="w-100 h-100"
          />
         
        </div>
      </div>

      <div class="col-9 row align-items-end">
        <div class="col-4 my-3">
          <label for="">Catégorie</label>
          <div class="fw-bold">
            <span v-if="item.section">
                {{ item.section.fullName }}
            </span>
          </div>
        </div>

        <div class="col-8 my-3">
          <label for="">Désignation</label>
          <div class="fw-bold">
            {{item.fullName}}
          </div>
        </div>

        <div class="col my-3">
          <label for=""> Prix TTC</label>
          <div class="fw-bold">
            {{item.priceTTC}}
          </div>
        </div>

        <div class="col my-3">
          <label for="">Unité </label>
          <div class="fw-bold">
            {{ item.measuring }}
          </div>
         
        </div>

        <div class="col my-3">
          <label for=""> TVA </label>
          <div class="fw-bold">
            {{ item.tva }}
          </div>
          
        </div>

        <div class="col-12 my-3">
          <label for="">Description</label>
          <div class="fw-bold">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>

    <br />
    
    <div class="row justify-content-end align-items-end">
      
      <div class="col-2">
        <label for=""> Du </label>
        <input v-model="filter.dateStart" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> Au </label>
        <input v-model="filter.dateEnd" type="date" class="form-control" />
      </div>

      <div class="col"></div>
      
      <div class="col-auto">
        <label for=""> </label>
        <button @click="search(filter)" class="btn btn-secondary">
          <i class="bi bi-funnel"></i>
          <span>
            Rechercher
          </span>
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          <span>
            Actualiser
          </span>
        </button>
      </div>
    </div>
    <br />
   <div class="row">
      <div class="col-6">
         <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Fournisseur</th>
              <th scope="col">Date de reception</th>
              <th scope="col">Prix d'achat</th>
              <th scope="col">Quantité Achtee</th>
              <th scope="col"> Stock </th>
              <th scope="col">Créer par</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(purchaseItem, index) in itemTransactions.purchases" :key="index++">
              <td>
                {{ index }}
              </td>
              
              <td>
                <span v-if="purchaseItem.purchase">
                  <span v-if="purchaseItem.purchase.supplier" class="fw-bold">
                    {{ purchaseItem.purchase.supplier.fullName }}
                    {{ purchaseItem.purchase.supplier.society }}
                  </span>
                </span>
              </td>

              <td>
                <span v-if="purchaseItem.purchase">
                  {{ purchaseItem.purchase.date | date }}
                </span>
              </td>
              <td class="">
                <span v-if="purchaseItem.priceTTC != null">
                  {{ purchaseItem.priceTTC }}
                </span>
              </td>
              <td class="">
                <span v-if="purchaseItem.quantity != null">
                  {{ purchaseItem.quantity }}
                </span>
              </td>

              <td>
                <span v-if="purchaseItem.stock">
                  {{ purchaseItem.stock.fullName }}
                </span>
              </td>

              <td>
                <span v-if="purchaseItem.user">
                  {{ purchaseItem.user.name }}
                </span>
              </td>

              <td class="p-0 m-0">
                <button
                  @click="
                    $router.push({
                      name: 'purchases-details',
                      params: { reference: purchaseItem.reference },
                    })
                  "
                  class="btn"
                >
                  <i class="bi bi-info-square fs-5"></i>
                </button>
              </td>
            </tr>
          </tbody>
          
          <tfoot>
            <tr>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col">
                <span class="fw-bold px-2 py-1 rounded bg-success text-white">
                  {{ purchaseTotalQuantity }}
                </span>
              </td>

              <td scope="col"></td>
              <td scope="col"></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-6">
         <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Client</th>
            <th scope="col">Date sortie</th>
            <th scope="col">Prix vente</th>
            <th scope="col">Quantité Vendue</th>
            <th scope="col"> Stock</th>
            <th scope="col">Créer par</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(saleItem, index) in itemTransactions.sales" :key="index++">
            <td>
              {{ index }}
            </td>
            
            <td>
              <span v-if="saleItem.sale">
                <span v-if="saleItem.sale.customer" class="fw-bold">
                  {{ saleItem.sale.customer.fullName }}
                  {{ saleItem.sale.customer.society }}
                </span>
              </span>
            </td>

            <td>
              <span v-if="saleItem.sale">
                {{ saleItem.sale.date | date }}
              </span>
            </td>
            <td class="">
              <span v-if="saleItem.priceTTC != null">
                {{ saleItem.priceTTC }}
              </span>
            </td>
            <td class="">
              <span v-if="saleItem.quantity != null">
                {{ saleItem.quantity }}
              </span>
            </td>

            <td>
              <span v-if="saleItem.stock">
                {{ saleItem.stock.fullName }}
              </span>
            </td>

            <td>
              <span v-if="saleItem.user">
                {{ saleItem.user.name }}
              </span>
            </td>
            
          </tr>
        </tbody>

        <tfoot>
            <tr>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col"></td>
              <td scope="col">
                <span class="fw-bold px-2 py-1 rounded bg-danger text-white">

                  {{ saleTotalQuantity }}
                </span>
              </td>

              <td scope="col"></td>
              <td scope="col"></td>
            </tr>
          </tfoot>
      </table>
      </div>
   </div>
    
  </div>
</template> 

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
     search_value: "",
      addedToday: false,
      sortValue: "",
      item_reference: "",
      supplier_reference: "",
      itemCode: "",
      selectedItem: {}, 
      selectedSupplier: {},
      filter: {
        dateStart : new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
          dateEnd : new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0]
      },
    };
  },
  computed: {
    ...mapGetters("item", {
      item: "getItem",
      itemTransactions: "getItemTransactions",
      purchaseTotalQuantity:"getPurchaseTotalQuantity",
      saleTotalQuantity:"getSalesTotalQuantity"
    }),
   
  },
  methods: {
    async search(data){
       await (data.item_reference= this.item.reference);
        this.$store.dispatch("item/fetchItemTransactions", data );
    }
  },
  beforeMount() {
    this.$store.dispatch("item/show", this.$route.params.reference);
  },
};
</script>
